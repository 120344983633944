$pc: white;
$sc: #ff8900;
$tc: #ae8200;

:root {
    --lh: 1.38rem;
    --pc: #005f51; 
    --sc: #ff8900;
    --tc: #ae8200;
    --warning: #ee4c30;
    --white: white;
    --dark: black;
    --darkhalf: rgba(0,0,0,0.3);
    --lightgray: rgb(211, 211, 211);
    
    --background: #E1DFC9;
    --navcolor: #8000ff;

    --color2: #00ff00;
    --color: black;
}



$radius: 0.5em;
$lh: 1.4em;
$big: 1.55rem;
$medium: 1.25rem;


$g1: linear-gradient(90deg, var(--sc) 0%, black 35%, var(--sc) 100%);