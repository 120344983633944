@import "../../style/variables.scss";

.chat {
    position: fixed;
    bottom: $lh * 2 + $lh / 3;
    right: $lh / 4;
    top: $lh / 4;
    padding: $lh / 4;
    padding-top: 0;
    z-index: 100;
    border-radius: var(--radius2);
    width: 15em;
    // pointer-events: none;
    background-color: rgba(240,142,53,0.2)

}

.chat-block {
    border-top: 1px solid #ac8f43;
    padding: $lh /4;
    // background: red;
}

.chat-block:nth-last-of-type(-n+3 ) {
    background: red;
}

@for $i from 0 through 10 {

    .chat-block:nth-last-of-type(#{$i} ) {
        // background-color: lighten(rgb(100,100,100), $i * 5%);
        background-color: opacify(rgba(32,92,80,0), (10 - $i) * 0.1);
    }
  }
/*
    chat-messages
     L  chat-image (avatars)
     L  chat-content
         L  chat-name
         L  chat-content-message
         L  chat-content-mymessage
*/

.chat-messages {
    height: calc(100% - 2em);
    width: 100%;
    overflow-y: auto;
    word-break: break-all;
}

.chat-image {
    width: $lh * 1.7;
    height: $lh * 1.7;
    // left: 10px;
    margin-left: 7px;
    margin-top: 2px;
    float: left;
    // display: inline-block;
    border-radius: 100%;
    overflow: hidden;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.8));

    img {
        width: 100%;
        height: 100%;
    }
}

.chat-content {
    max-width: 10em;
    height: 100%;
    // background: var(--brightblue);
    // border-radius: var(--radius);
     padding: $lh / 4;
     padding-top: $lh / 6;
    
    margin-bottom: $lh / 4;
    margin-left: 3.5em;
   
}

.chat-name {
    color: var(--tc);
    font-size: var(--mini2);
    margin-bottom: 0.25rem;
}

.chat-content-message {
    color: white;
    margin-top: 0.1em;
    font-size: var(--small2);
    width: 10em;
    // background: red;
}

// .chat-content-mymessage {
//     // background: var(--tc);
//     color: var(--sc);
// }

/*
Chat Bar
    includes chat-bar, chat-input
    chat-image class is same for Bar and Messages
*/

.chat-bar {
    position: absolute;
    bottom: 0;
    width: 100%;
    
    // padding-right: 1em;
    // padding-left: 0.5em;
}

.chat-input {
    width: 100%;
    height: 2.5em;
    margin-top: 0.5rem;
}

.chat-input-form{
     border-radius: var(--radius);
    width: 100%;
}

.chat-input-field{
    // font-size: inherit;
    width: 11rem;
    height: 2rem;
    background-color: var(--tc);
    color: black;
    border-radius: 0;
    border: none;
    padding-left: 0.5em;
    color: var(--sc);
}

.chat-input-submit{
    cursor: pointer;
    float: right;
    width: 2em;
    height: 2em;
    font-size: inherit;
    background: var(--tc);
    border: none;
    border-radius: 100em;
}

.chat-input-submit:hover{
    opacity: 0.85;
}

.chat-bar-image{
    width: $lh * 1.8;
    height: $lh * 1.8;
}

.chat-status {
    margin-top: $lh / 2;
    background: rgba(0, 0, 0, 0.5);
    border-radius: $radius;
    position: absolute;
    bottom: $lh / 4;
    padding: $lh / 4;
    padding-left: $lh / 2;
    padding-right: $lh / 2;
    left: $lh * 3 ;
    z-index: 1000;
    position: fixed;
    font-size: 1.25rem;

    a {
        color: white;
        margin-right: $lh / 2;
        text-decoration: none;
        opacity: 0.5;
        border-bottom: 2px solid;
        padding-bottom: 0.07em;
        cursor: pointer;
    }

    @media only screen and (max-width: 750px) {
        bottom: $lh * 1.8;
        left: $lh / 4;
    }
}

.chat-status-profile {
    display: inline-block;
    img {
        margin-left: 1em;
        width: 1.4em;
        height: 1.4em;
        border-radius: 5em;
        vertical-align: middle;
    }
}

