.profile-container {
    margin: $lh;
    padding: $lh / 2;
    width: 20em;
    background:rgba(255, 255, 255, 0.4);;
    border-radius: $radius;
    position: absolute;
    left: 50%;
    top: $lh * 4;
    transform: translate(-50%,0);
}

.profile-image-container {
    margin-top: $lh;
}

input {
    font-size: inherit;
    border: none;
    border-radius: $lh / 8;
    margin-top: $lh / 4;
    padding: $lh / 4;
}

.profile-button {
    font-family: "NH","Helvetica Neue", arial, sans-serif;
    cursor: pointer;
    user-select: none; 
    font-size: inherit;
    text-decoration: none;
    text-align: center;
    filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.8));
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.5);
    color: black;
    background: #ae8200;

    padding: $lh / 2;
    margin-top: $lh ;
    z-index: 700;
}

.profile-save {
    width: 5rem;
}

.profile-button-inactive {
    opacity: 0.5;
}

.profile-progress {
    margin-top: $lh / 2;
    margin-bottom: $lh ;
}