.livestream {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 900;
    background: var(--pc);
    padding: $lh * 5;
    padding-top: $lh / 2;
    box-shadow: inset 0px 0px 25px rgba(255,255,255,0.1);
    overflow: scroll;

    @media only screen and (max-width: 600px) {
        padding: $lh  / 2;
        padding-top: $lh  / 2;
    }
}

.livestream-overlay {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 900;
    background: rgba(0,0,0,0.7);
    backdrop-filter: blur(0.8em);
    padding: $lh * 5;
    padding-top: $lh / 2;
    box-shadow: inset 0px 0px 25px rgba(255,255,255,0.1);
    overflow: scroll;
}

.livestream-title {
    margin-bottom: $lh;
    margin-top: $lh;
    //padding-left: $lh * 3;

    @media only screen and (max-width: 600px) {
        padding-left: $lh * 4;
        margin-bottom: $lh * 4;
    }
}


.livestream-container {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.livestream-block {
    display: inline-block;
    position: relative;
    width: calc(49% - 2em);
    padding: $lh;
    background: #ae8200;
    border-radius: 0.5em;
    z-index: 900;
    padding: 1.4em;
    backdrop-filter: blur(6px);
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.8));
    box-shadow: inset 0px 0px 10px rgb(0 0 0 / 50%);
    margin: 0.5em

}

.livestreamclose {
    cursor: pointer;
    z-index: 950;
    position: fixed;
    top: 0;
    right: 0;
    background: #ae8200;
    margin: 0.7em;
    padding: 0.7em;
    padding-bottom: 0.35em;
    color: white;
    text-decoration: none;
    border-radius: 100em;
    text-align: center;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.8));
    box-shadow: inset 0px 0px 10px rgb(0 0 0 / 50%);
}

.song-title {
    text-align: center;
    margin-bottom: $lh;
}
